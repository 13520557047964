body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

/* pdfheadercss */
#responsivepdfheader {
  width:95%;
  margin:auto;
  padding:30px 0px;
}

@media screen and (max-width: 600px) {
  #responsivepdfheader {
    padding: 30px 10px;
  }
  #responsivepdfheader b,
  span {
    font-size: 8px !important;
    line-height: 10px !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 12px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C1C1C1; 
  border-radius: 10px;
}
